<template>
    <v-container fluid>
        <v-card>
            <Page-Header
                :title="$t('reports.studentsReport')"
                icon="mdi-credit-card-outline"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="print"
                    >
                        <v-icon>
                            mdi-printer-outline
                        </v-icon>
                        {{ $t("print") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-expansion-panels class="pa-5" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon color="primary darken-1">
                            mdi-clipboard-text-search-outline
                        </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="3" sm="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="fiscalYear"
                                            :items="fiscalYears"
                                            item-text="title"
                                            item-value="fiscalYearGuid"
                                            :label="$t('fiscalYear.fiscalYear')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="schoolStage"
                                            :items="schoolStages"
                                            item-text="stageName"
                                            item-value="schoolStageGuid"
                                            :label="$t('school.stageName')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="semesterGuid"
                                            :items="semesters"
                                            item-text="semesterName"
                                            item-value="semesterGuid"
                                            :label="$t('school.semesterName')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="classGuid"
                                            :items="classes"
                                            item-text="className"
                                            item-value="classGuid"
                                            :label="$t('school.className')"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="getReport"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon
                                    >mdi-clipboard-text-search-outline</v-icon
                                >
                                {{ $t("preview") }}
                            </v-btn>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table
                :headers="headers"
                :items="students"
                class="pa-5"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template slot="body.append">
                    <tr>
                        <th>{{ $t("total") }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            {{ totalAmounts | currency("", floatingPoints) }}
                        </th>
                        <th>
                            {{ totalRemaining | currency("", floatingPoints) }}
                        </th>
                    </tr>
                </template>
                <template v-slot:[`item.studentName`]="{ item }">
                    <v-badge
                        v-if="item.hasUnpaidPayments"
                        color="red"
                        dot
                        left
                        bordered
                        >{{ item.studentName }}</v-badge
                    >
                    <span v-else>{{ item.studentName }}</span>
                </template>
                <template v-slot:[`item.birthday`]="{ item }">
                    {{ item.birthday | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.totalBillsAmount`]="{ item }">
                    {{ item.totalBillsAmount | currency("", floatingPoints) }}
                </template>
                <template v-slot:[`item.totalRemainingAmount`]="{ item }">
                    {{
                        item.totalRemainingAmount | currency("", floatingPoints)
                    }}
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: {
        PageHeader
    },
    computed: {
        totalAmounts: {
            get() {
                if (this.students != null)
                    return this.students.reduce((accumulator, object) => {
                        return accumulator + object.totalBillsAmount;
                    }, 0);
                return 0;
            },
            set() {}
        },
        totalRemaining: {
            get() {
                if (this.students != null)
                    return this.students.reduce((accumulator, object) => {
                        return accumulator + object.totalRemainingAmount;
                    }, 0);
                return 0;
            },
            set() {}
        }
    },
    data() {
        return {
            floatingPoints: 0,
            panel: [0, 1],
            fiscalYears: [],
            fiscalYear: null,
            semesterGuid: null,
            schoolStage: null,
            classGuid: null,
            valid: true,
            loading: true,
            classes: [],
            schoolStages: [],
            semesters: [],
            students: [],
            headers: [
                {
                    text: this.$t("customer.fullName"),
                    value: "studentName"
                },
                {
                    text: this.$t("school.birthday"),
                    value: "birthday"
                },
                {
                    text: this.$t("school.stageName"),
                    value: "stageName"
                },
                {
                    text: this.$t("school.semester"),
                    value: "semesterName"
                },
                {
                    text: this.$t("school.class"),
                    value: "className"
                },
                {
                    text: this.$t("school.section"),
                    value: "section"
                },
                {
                    text: this.$t("totalBillsAmount"),
                    value: "totalBillsAmount"
                },
                {
                    text: this.$t("totalRemainingAmount"),
                    value: "totalRemainingAmount"
                }
            ]
        };
    },
    created() {
        this.loading = true;
        this.getFilterData();
    },
    methods: {
        getReport() {
            if (this.$refs.form.validate()) {
                axios
                    .get("Students/StudentsReport", {
                        params: {
                            fiscalYearGuid: this.fiscalYear,
                            classGuid: this.classGuid,
                            semesterGuid: this.semesterGuid,
                            schoolStageGuid: this.schoolStageGuid
                        }
                    })
                    .then(response => {
                        this.students = response.data.data;

                        this.totalAmounts =
                            Math.round(
                                (this.students.reduce(
                                    (a, b) => a + (b["paymentAmount"] || 0),
                                    0
                                ) +
                                    Number.EPSILON) *
                                    100
                            ) / 100;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        getFilterData() {
            axios
                .get("Students/GetStudentsReportFilterData")
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.semesters = response.data.data.semesters;
                        this.classes = response.data.data.classes;
                        this.schoolStages = response.data.data.schoolStages;
                        this.fiscalYears = response.data.data.fiscalYears;
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        print() {
            axios({
                url: "Students/PrintStudentsReport",
                method: "GET",
                responseType: "blob",
                params: {
                    fiscalYearGuid: this.fiscalYear,
                    classGuid: this.classGuid,
                    semesterGuid: this.semesterGuid,
                    schoolStageGuid: this.schoolStageGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "تقرير الطلاب.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        }
    }
};
</script>
